import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState, useRef } from "react"
import { Fade } from "react-reveal"
import {
	faCheckCircle,
	faEuroSign,
	faInfoCircle,
} from "@fortawesome/free-solid-svg-icons"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Seo from "../../../components/seo"

const list = [
	{
		text: "Anforderungsprofil",
	},
	{
		text: "Stellenanzeige & Posting",
	},
	{
		text: "Kandidatenpoolerstellung",
	},
	{
		text: "Reaktivierung von Kandidaten",
	},
	{
		text: "Active Sourcing",
	},
	{
		text: "Telefonische Direktansprache",
	},
	{
		text: "Führen von Kandidatengesprächen",
	},
	{
		text: "Kandidatenprofilerstellung & Versendung",
	},
	{
		text: "Terminierung von Telefoninterviews mit dem GF",
	},
]

const Page = () => {
	return (
		<div className="w-full min-h-screen bg-primary-50">
			<Seo
				title="Angebot für Civitalis GmbH - Senior Recruiter Vermittlung"
				description="Angebot für Civitalis GmbH - Senior Recruiter Vermittlung"
				noIndex
			/>
			<div className="px-2 pt-10 mx-auto sm:px-6 sm:max-w-2xl lg:px-8 lg:max-w-[1500px]">
				<Link className="hover:underline" to="/angebot/4894">
					Zurück zu den Angeboten
				</Link>
			</div>
			<div className="px-2 mx-auto sectionpadding sm:px-6 sm:max-w-2xl lg:px-8 lg:max-w-[1500px]">
				<StaticImage
					quality={100}
					width={400}
					src="../../../images/logo.png"
					alt=""
					placeholder=""
					loading="eager"
					className="mx-8 mb-4 sm:mx-auto lg:float-right"
				/>
				<h1 className="mb-16 font-bold h1 text-primary">
					Senior Recruiter Vermittlung
				</h1>
				<div className="grid w-full gap-10 xl:grid-cols-3">
					<Fade up>
						<table className="overflow-hidden rounded shadow xl:col-span-2">
							<tr className="text-xl">
								<th className="w-1/2 px-4 py-3 text-center bg-gray-50 sm:px-8">
									Leistungsinhalte
								</th>
							</tr>
							{list.map((item, index) => (
								<tr key={index}>
									<td
										className="w-1/2 px-4 py-3 align-top bg-white border-t border-r sm:px-8"
										rowSpan={item.beforeRows || 1}
									>
										<Fade down delay={index * 100}>
											<Cell
												text={item.text}
												info={item.info}
												icon={item.icon || faCheckCircle}
												iconClassName="text-green-500"
											/>
										</Fade>
									</td>
								</tr>
							))}
						</table>
					</Fade>
					<div>
						<Fade right>
							<div className="max-w-md p-5 mx-auto bg-white rounded shadow">
								<h2 className="text-2xl font-bold text-primary">€ Angebot</h2>
								<p className="text-xl font-bold text-primary">
									Senior Recruiter Vermittlung
								</p>
								<div className="mt-4">
									<h3 className="text-lg font-bold">VAR I</h3>
									<p>
										<span className="font-bold text-secondary">12.000 €</span> +
										MwSt. bei Vertragsunterschrift mit dem Kandidaten (m/w/d)
									</p>
									<h3 className="mt-4 text-lg font-bold">VAR II</h3>
									<p>
										<span className="font-bold text-secondary">8.000 €</span>{" "}
										bei Vertragsunterschrift mit dem Kandidaten (m/w/d)
									</p>
									<p>
										<span className="font-bold text-secondary">8.000 €</span>{" "}
										nach sechs Monaten, wenn sich der Recruiter im ungekündigten
										Arbeitsverhältnis befindet
									</p>
									<h3 className="mt-4 text-lg font-bold">
										Möglicher Suchbeginn
									</h3>
									<p>
										<span className="font-bold text-secondary">07.02.22</span>{" "}
										bzw.{" "}
										<span className="font-bold text-secondary">14.02.22</span>
									</p>
									<h3 className="mt-4 text-lg font-bold">Sonstiges</h3>
									<p>
										Für die ersten vier Wochen haben wir den Suchauftrag
										exklusiv
									</p>
								</div>
							</div>
						</Fade>
					</div>
				</div>
				<Fade up>
					<div className="flex flex-wrap justify-center gap-8 mt-16">
						<Link
							to="/angebot/4894/recruiter-mieten"
							className="btn btn-secondary"
						>
							Recruiter mieten
						</Link>
						<Link to="/angebot/4894/website" className="btn btn-secondary">
							Relaunch von civitalis.eu
						</Link>
					</div>
				</Fade>
			</div>
		</div>
	)
}

export default Page

const Cell = ({ text, info, icon, iconClassName }) => {
	const randomID = useRef(Math.floor(Math.random() * 100000))
	const [open, setopen] = useState(false)

	return (
		<>
			<div className="flex gap-2">
				<FontAwesomeIcon
					icon={icon}
					className={" " + iconClassName}
					size="lg"
				/>

				<div className="flex-grow">{text}</div>

				{info && (
					<button
						aria-expanded={open}
						aria-controls={`panel-${randomID.current}`}
						type="button"
						onClick={() => {
							setopen((prev) => !prev)
						}}
					>
						<FontAwesomeIcon
							icon={faInfoCircle}
							className="text-gray-400"
							size="lg"
						/>
					</button>
				)}
			</div>
			<div
				id={`panel-${randomID.current}`}
				className={"leading-tight px-7 mt-2 text-xs " + (open ? "" : "hidden")}
			>
				<div>{info}</div>
			</div>
		</>
	)
}
